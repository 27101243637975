import { Box } from '@mui/material'

import { ProductBanner } from './ProductBanner'
import { StickyNavigation } from './StickyNavigation'

const dataCy = 'BannerWithNavigation'

const BannerWithNavigation = () => {
  return (
    <Box sx={{ position: 'sticky', top: 0, zIndex: 1200 }} data-cy={dataCy}>
      <ProductBanner />

      <StickyNavigation />
    </Box>
  )
}

export default BannerWithNavigation
