import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import { Chip, Container, Stack, Typography } from '@mui/material'

import { KnaufImage } from '@/components/KnaufImage'
import { useProduct } from '@/providers/p/ProductProvider'
import { generateLineClamp } from '@/utils/frontend/generateLineClamp'
import { useBreakpoints } from '@/utils/frontend/useBreakpoints'
import { useT } from '@/utils/frontend/useT'
import { generateImageAlt } from '@/utils/plain/generateImageAlt'

const dataCy = 'ProductBanner'

export const ProductBanner = () => {
  const { t } = useT({ keyPrefix: 'common' })

  const { product } = useProduct()
  const { name, images, shortDescription, isDIY } = product

  // get the main image
  const image = images[0]

  const { isDesktop } = useBreakpoints()

  return (
    <Container maxWidth={false} disableGutters sx={{ bgcolor: 'primary.main' }}>
      <Container
        maxWidth="xl"
        sx={{
          py: 1,
        }}
      >
        <Stack
          direction="row"
          sx={[
            {
              justifyContent: 'space-between',
              alignItems: 'center',
              flexWrap: 'wrap',
              gap: 1,
            },
          ]}
        >
          {/* Image + title and description */}
          <Stack spacing={2} direction="row" sx={{ alignItems: 'center' }}>
            {isDesktop ? (
              <KnaufImage
                src={image?.url}
                adobeParams={{ hei: 64 }}
                alt={generateImageAlt({ productName: image?.name })}
                dataCy={`${dataCy}-image`}
                loading="eager"
                style={{
                  height: 64,
                  width: 'auto',
                }}
              />
            ) : null}

            <Stack spacing={1}>
              <Typography
                variant={isDesktop ? 'body1boldDense' : 'body2boldDense'}
                color="secondary.contrastText"
                sx={generateLineClamp(1)}
                data-cy={`${dataCy}-name`}
              >
                {name}
              </Typography>
              {isDesktop && (
                <Typography
                  variant="body3dense"
                  color="secondary.contrastText"
                  sx={generateLineClamp(1)}
                  data-cy={`${dataCy}-shortDescription`}
                >
                  {shortDescription}
                </Typography>
              )}
            </Stack>
          </Stack>
          {isDIY && (
            <Chip
              key="availability-chip-sticky-banner"
              variant="outlined"
              color="inherit-white"
              icon={<LocationOnOutlinedIcon />}
              label={t('DIY-label')}
            />
          )}
        </Stack>
      </Container>
    </Container>
  )
}
