import type { PropsWithChildren } from 'react'
import { createContext, useContext } from 'react'

import { useDeepCompareMemo } from 'use-deep-compare'

import type { Product } from '@/types'

type ProductContextType = {
  product: Product
}

type ProductProviderProps = {
  product: Product
}

const initialState: ProductContextType = {
  product: null as unknown as Product,
}

export const ProductContext = createContext(initialState)
export const useProduct = () => useContext(ProductContext)

export const ProductProvider = (props: PropsWithChildren<ProductProviderProps>) => {
  const { product, children } = props

  const memoValue = useDeepCompareMemo(() => ({ product }), [product])

  return <ProductContext.Provider value={memoValue}>{children}</ProductContext.Provider>
}
