import dynamic from 'next/dynamic'

import { useBreakpoints } from '@/utils/frontend/useBreakpoints'

const DynamicImageGalleryDesktop = dynamic(() =>
  import('./ImageGalleryDesktop').then((mod) => mod.ImageGalleryDesktop),
)

const DynamicImageGalleryMobile = dynamic(() =>
  import('./ImageGalleryMobile').then((mod) => mod.ImageGalleryMobile),
)

export const ImageGallery = () => {
  const { isDesktop } = useBreakpoints()

  if (isDesktop) {
    return <DynamicImageGalleryDesktop />
  }

  // mobile
  return <DynamicImageGalleryMobile />
}
