import logger from '@knauf-group/ct-shared-nextjs/logger'

import { removeDoubleSlashes } from './removeDoubleSlashes'

export const expandURL = (link: string, canonicalURL: string) => {
  if (link.startsWith('https://') || link.startsWith('http://')) {
    return link
  }

  if (!canonicalURL.startsWith('https://') && !canonicalURL.startsWith('http://')) {
    logger.error('`canonicalURL` should contain protocol', { link, canonicalURL })

    return link
  }

  if (link.startsWith('#')) {
    // points to the current page
    return removeDoubleSlashes(`${canonicalURL}${link}`)
  }

  if (link.startsWith('/')) {
    // can be http://knauf.com/en, knauf.com/en-gb, https://knauf.com/en/abc, etc.
    const { origin, pathname, host } = new URL(canonicalURL)

    const locale = pathname.split('/')[1]

    // if host is empty and cannot be inferred, that means something is wrong with the url, e.g. protocol is missing
    // `locale` should be a valid, e.g. de-DE, de-de, DE-DE, DE-de
    if (!host || !locale.match(/^[a-zA-Z]{2}-[a-zA-Z]{2}$/)) {
      logger.error('Unexpected canonicalURL', { link, canonicalURL })

      return link
    }

    return removeDoubleSlashes(`${origin}/${locale}${link}`)
  }

  logger.warning('Unexpected link to expand', { link, canonicalURL })

  return link
}
