/**
 * Validate if the string is a valid URL and has https protocol for remote images
 * @param urlString
 * @returns
 */
export const isValidUrl = (urlString: string) => {
  let url
  try {
    url = new URL(urlString)
  } catch (e) {
    return false
  }
  return url.protocol === 'https:'
}
