import { Stack, Typography } from '@mui/material'

import { DIYLabel } from '@/components/DIYLabel'
import { useProduct } from '@/providers/p/ProductProvider'

const dataCy = 'NameDescriptionDIY'

export const NameDescriptionDIY = () => {
  const { product } = useProduct()
  const { name, shortDescription, isDIY } = product

  return (
    <Stack spacing={1}>
      <Typography component="h1" variant="h5" data-cy={`${dataCy}-title`}>
        {name}
      </Typography>
      <Typography variant="body2bold" data-cy={`${dataCy}-shortDescription`}>
        {shortDescription}
      </Typography>
      {isDIY && <DIYLabel dataCy={dataCy} boxSx={{ alignSelf: 'flex-start' }} />}
    </Stack>
  )
}
