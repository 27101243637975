import { RootPath } from '@/constants/routing'

import { removeDoubleSlashes } from '../plain/removeDoubleSlashes'

// CALL IT ONLY FROM BACKEND
export const getCanonicalHref = (
  locale: string,
  path: string | string[],
  slug?: string,
): string => {
  const baseUrl = removeDoubleSlashes(
    `${process.env.PUBLIC_HOST_BASE_URL}/${locale}/${RootPath}/${
      Array.isArray(path) ? path[0] : path
    }`,
  )
  return baseUrl + (slug ? `/${slug}` : '')
}
