import { useRouter } from 'next/router'
import { NextSeo } from 'next-seo'

import { Box } from '@mui/material'

import { ProductProvider } from '@/providers/p/ProductProvider'
import ProductDetailsTemplate from '@/templates/ProductDetailsPage'
import type { ProductDetailsNextPageNoErrorProps } from '@/types'
import { getSectionsToHide } from '@/utils/plain/getSectionsToHide'

const ProductDetailsPage = (props: ProductDetailsNextPageNoErrorProps) => {
  const { product } = props
  const { seo } = product
  const { metaTitle, metaDescription } = seo

  const { locale } = useRouter()

  return (
    <Box>
      <NextSeo title={metaTitle} description={metaDescription} />
      <ProductProvider product={product}>
        <ProductDetailsTemplate sectionsToHide={getSectionsToHide(locale)} />
      </ProductProvider>
    </Box>
  )
}

export default ProductDetailsPage
