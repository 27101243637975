import logger from '@knauf-group/ct-shared-nextjs/logger'
import type { SharedContentfulProps } from '@knauf-group/ct-shared-nextjs/web'
import { sharedContentfulProps } from '@knauf-group/ct-shared-nextjs/web'

const DEFAULT_SHARED_PROPS: SharedContentfulProps = { headerEntries: null, footerEntries: null }

export const getConfentfulSharedProps = async (locale: string) => {
  try {
    return await sharedContentfulProps({ locale })
  } catch (error) {
    logger.error(`Error sharedContentfulProps`, { error })
    return DEFAULT_SHARED_PROPS
  }
}
