import type { GetStaticPaths, GetStaticProps, NextPage } from 'next'
import Head from 'next/head'
import type { Locale } from 'next/router'

import type { IAppsConfig } from '@knauf-group/ct-shared-nextjs/client'
import logger from '@knauf-group/ct-shared-nextjs/logger'
import { Box } from '@mui/material'
import { HttpStatusCode, isAxiosError } from 'axios'

import ProductDetailsPage from '@/components/ProductDetailsPage/Page'
import { PageLayout } from '@/layouts/PageLayout'
import type { ProductDetailsNextPageProps } from '@/types'
import { endpoints } from '@/utils/backend/api'
import { getCanonicalHref } from '@/utils/backend/getCanonicalHref'
import { getCommonServerProps } from '@/utils/backend/getCommonServerProps'
import { getProductDetailProperties } from '@/utils/frontend/analytics/propertiesHelpers'
import { useProductDetailPageView } from '@/utils/frontend/analytics/usePageView'
import { useTrackScrollPosition } from '@/utils/frontend/analytics/useTrackScrollPosition'
import { extractIdFromSlug } from '@/utils/plain/extractIdFromSlug'
import { getFolderName } from '@/utils/plain/getFolderName'
import { isEmpty } from '@/utils/plain/isEmpty'
import { isValidProductId } from '@/utils/plain/product.utils'

type PageProps = ProductDetailsNextPageProps & {
  appsConfig: IAppsConfig
}

export const PDP: NextPage<PageProps> = (props) => {
  const { canonicalHref, footerEntries, headerEntries, appsConfig, product } = props

  useProductDetailPageView(product)
  useTrackScrollPosition(getProductDetailProperties(product))

  return (
    <Box>
      <Head>
        <link rel="canonical" href={canonicalHref} />
      </Head>
      <PageLayout
        footerEntries={footerEntries}
        headerEntries={headerEntries}
        appsConfig={appsConfig}
      >
        <ProductDetailsPage product={product} />
      </PageLayout>
    </Box>
  )
}

export const generateGetStaticPropsFn: (productPath: string) => GetStaticProps =
  (productPath) => async (ctx) => {
    const locale = ctx?.locale as Locale

    const productSlug = ctx.params?.slug as string

    if (isEmpty(productSlug)) {
      return {
        // 'product-slug-missing' error
        notFound: true,
      }
    }

    const productId = extractIdFromSlug(productSlug)
    if (isEmpty(productId) || !isValidProductId(productId)) {
      return {
        // 'product-id-missing' error or wrong product id
        notFound: true,
      }
    }

    try {
      const { data: product } = await endpoints.getProduct(locale, productId)
      const canonicalHref = getCanonicalHref(locale!, productPath, product.slug)

      return {
        props: {
          key: productId,
          product,
          ...(await getCommonServerProps({ locale, canonicalHrefPath: canonicalHref })),
          canonicalHref,
        },
        revalidate: parseInt(process.env.ISR_REVALIDATE_TIME, 10),
      }
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status

        if (status === HttpStatusCode.NotFound) {
          return {
            // 'product-not-found' error
            notFound: true,
          }
        }
      }
      logger.error('unexpected error in getProduct', { error })
      throw error
    }
  }

export const getStaticPaths: GetStaticPaths = async () => {
  return {
    paths: [],
    fallback: 'blocking',
  }
}

export default PDP

export const getStaticProps = generateGetStaticPropsFn(getFolderName(__filename))
