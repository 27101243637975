import { Stack, Typography } from '@mui/material'

import { useProduct } from '@/providers/p/ProductProvider'
import { useT } from '@/utils/frontend/useT'
import { isEmpty } from '@/utils/plain/isEmpty'

import { CertificateScroller } from './CertificateScroller'

const Certificates = () => {
  const { product } = useProduct()
  const { certificateImages } = product

  const { t } = useT({ keyPrefix: 'product' })

  if (isEmpty(certificateImages)) return null

  return (
    <Stack spacing={2}>
      <Typography variant="body2bold" data-cy="certificate-title">
        {t('certificate-title')}
      </Typography>
      <CertificateScroller certificateImages={certificateImages} dataCy="certificate" />
    </Stack>
  )
}
export default Certificates
