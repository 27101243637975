import logger from '@knauf-group/ct-shared-nextjs/logger'

import { useProduct } from '@/providers/p/ProductProvider'
import type { DownloadableFile } from '@/types'

import { getLanguageNameFromCode } from '../../plain/getLanguageName'
import {
  defaultValues,
  getDownloadFileProperties,
  getProductDetailProperties,
} from './propertiesHelpers'
import { useInitializeAnalytics } from './useInitializeAnalytics'

export const useProductDetailAnalyticsTracking = () => {
  const { analytics, isReady } = useInitializeAnalytics()
  const { product } = useProduct()
  const productDetailPageProperties = getProductDetailProperties(product)

  if (!isReady) {
    const warnFn = () =>
      logger.warn('Trigger analytics tracking before initialization is ready')

    return {
      trackDownload: warnFn,
      trackTabSwitchEvent: warnFn,
      trackClickEvent: warnFn,
    }
  }

  const trackDownload = ({
    action,
    files,
    language,
    target,
  }: {
    action: string
    files: DownloadableFile[]
    language: string
    target: string
  }) => {
    const fileProperties = getDownloadFileProperties(files)

    const languageName = getLanguageNameFromCode(language)

    analytics.track('download', {
      ...defaultValues,
      ...productDetailPageProperties,
      ...fileProperties,
      ...(languageName && { language: languageName }),
      action,
      target,
    })
  }

  const trackTabSwitchEvent = (tabName: string) => {
    analytics.track('tab_switch', {
      ...defaultValues,
      ...productDetailPageProperties,
      action: tabName,
    })
  }

  const trackClickEvent = (
    action: string,
    target: string,
    eventSpecification: 'internal_cta_click' | 'internal_link_click' | 'external_cta_click',
  ) => {
    analytics.track('click', {
      ...defaultValues,
      ...productDetailPageProperties,
      action,
      target,
      event_specification: eventSpecification,
    })
  }

  return { trackDownload, trackTabSwitchEvent, trackClickEvent }
}
