import { Grid, Stack } from '@mui/material'

import { useBreakpoints } from '@/utils/frontend/useBreakpoints'

import Certificates from '../../Certificates'
import { ImageGallery } from '../../ImageGallery'
import { ProductInformationSection } from '../ProductInformationSection'
import { NameDescriptionDIY } from './NameDescriptionDIY'

const dataCy = 'FirstViewportSection'

const FirstViewportSection = () => {
  const { isDesktop, isNotDesktop } = useBreakpoints()

  return (
    <Grid container spacing={{ xs: 2, lg: 3 }} sx={{ pb: 4 }} data-cy={dataCy}>
      {/* Product title and caption for xs view */}
      {isNotDesktop && (
        <Grid item xs={12}>
          <NameDescriptionDIY />
        </Grid>
      )}

      {/* Image slider */}
      <Grid item xs={12} lg={6}>
        <Stack spacing={4}>
          <ImageGallery />
          {isDesktop && <Certificates />}
        </Stack>
      </Grid>

      {/* Product information section */}
      <Grid item xs={12} lg={6}>
        <ProductInformationSection />
      </Grid>
    </Grid>
  )
}

export default FirstViewportSection
