import type { ComponentType } from 'react'
import dynamic from 'next/dynamic'

import { Box, Container, Stack } from '@mui/material'
import { get } from 'lodash'

import BannerWithNavigation from '@/components/ProductDetailsPage/BannerWithNavigation'
import { ProductDetailsBreadcrumb } from '@/components/ProductDetailsPage/ProductDetailsBreadcrumbs'
import FirstViewportSection from '@/components/ProductDetailsPage/sections/FirstViewportSection'
import type { Section } from '@/constants'
import { SECTIONS_MAP } from '@/constants'
import { useProduct } from '@/providers/p/ProductProvider'
import { isNotEmpty } from '@/utils/plain/isEmpty'

const DynamicTechnicalDataSection = dynamic(
  () => import('@/components/ProductDetailsPage/sections/TechnicalDataSection'),
)
const DynamicAccessoriesSection = dynamic(
  () => import('@/components/ProductDetailsPage/sections/AccessoriesSection'),
)
const DynamicCertificatesSection = dynamic(
  () => import('@/components/ProductDetailsPage/sections/CertificatesSection'),
)
const DynamicDescriptionAndDetailsSection = dynamic(
  () => import('@/components/ProductDetailsPage/sections/DescriptionAndDetailsSection'),
)
const DynamicDocumentsSection = dynamic(
  () => import('@/components/ProductDetailsPage/sections/DocumentsSection'),
)
const DynamicRelatedProductsSection = dynamic(
  () => import('@/components/ProductDetailsPage/sections/RelatedProductsSection'),
)
const DynamicVariantsSection = dynamic(
  () => import('@/components/ProductDetailsPage/sections/VariantsSection'),
)

const sectionsMap = {
  description: DynamicDescriptionAndDetailsSection,
  documents: DynamicDocumentsSection,
  certificates: DynamicCertificatesSection,
  'technical-data': DynamicTechnicalDataSection,
  variants: DynamicVariantsSection,
  'similar-products': DynamicRelatedProductsSection,
  accessories: DynamicAccessoriesSection,
} as Record<Section, ComponentType<{}>>

const ProductDetailsTemplate = ({ sectionsToHide }: { sectionsToHide: Section[] }) => {
  const { product } = useProduct()

  return (
    <Box>
      <Container maxWidth="xl">
        <ProductDetailsBreadcrumb />
        <FirstViewportSection />
      </Container>
      {/* Banner with navigation */}
      <BannerWithNavigation />
      {/* Rest of the page */}
      <Container maxWidth="xl" sx={{ py: { xs: 3, lg: 5 } }}>
        <Stack
          sx={{
            // using gap instead of spacing because grid inside spacing stack has wrong css
            gap: { xs: 4, lg: 10 },
          }}
        >
          {Object.keys(sectionsMap).map((k) => {
            const key = k as keyof typeof SECTIONS_MAP

            const shouldDisplaySection =
              SECTIONS_MAP[key].atLeastOneAttributes.some((attribute) =>
                isNotEmpty(get(product, attribute)),
              ) && !sectionsToHide.includes(key)

            if (!shouldDisplaySection) return null

            const Section = sectionsMap[key]

            return <Section key={key} />
          })}
        </Stack>
      </Container>
    </Box>
  )
}

export default ProductDetailsTemplate
