import { useRouter } from 'next/router'

import type { SxProps, Theme } from '@mui/material'
import { Container, Tab, Tabs } from '@mui/material'
import { get } from 'lodash'

import type { Section } from '@/constants'
import { SECTIONS_MAP } from '@/constants'
import { useProduct } from '@/providers/p/ProductProvider'
import { useProductDetailAnalyticsTracking } from '@/utils/frontend/analytics/useProductDetailAnalyticsTracking'
import { useT } from '@/utils/frontend/useT'
import { getSectionsToHide } from '@/utils/plain/getSectionsToHide'
import { isEmpty, isNotEmpty } from '@/utils/plain/isEmpty'

const dataCy = 'StickyNavigation'

const styles = {
  navBar: {
    borderBottom: 1,
    borderColor: 'divider',
    bgcolor: 'background.default',
  } as SxProps,
  Tab: {
    textWrap: 'nowrap',
    '&:hover': (theme) => ({
      '@media (pointer: fine)': {
        color: 'text.primary',
        boxShadow: `inset 0 -2px 0 0 ${theme.palette.secondary.main}`,
      },
    }),
  } as SxProps<Theme>,
}

const useGetProductSections = (sectionsToHide: Section[]) => {
  const { product } = useProduct()
  const { t } = useT({ keyPrefix: 'product' })

  const sectionsMapKeysFiltered = Object.keys(SECTIONS_MAP).filter((k) => {
    const key = k as keyof typeof SECTIONS_MAP
    const item = SECTIONS_MAP[key]

    // filter only sections that have all required attributes
    const requiredAttributesExist = item.atLeastOneAttributes.some((attribute) =>
      isNotEmpty(get(product, attribute)),
    )

    // section isn't hidden
    const hideSection = sectionsToHide.includes(key)

    return requiredAttributesExist && !hideSection
  })

  return sectionsMapKeysFiltered.map((k) => {
    const key = k as keyof typeof SECTIONS_MAP

    const { id, tKey } = SECTIONS_MAP[key]

    const href = `#${id}`

    return {
      href,
      label: t(tKey),
      id,
    }
  })
}

export const StickyNavigation = () => {
  const { locale } = useRouter()
  const sections = useGetProductSections(getSectionsToHide(locale))
  const { trackTabSwitchEvent } = useProductDetailAnalyticsTracking()

  if (isEmpty(sections)) return null

  return (
    <Container maxWidth={false} disableGutters sx={styles.navBar} data-cy={dataCy}>
      <Container maxWidth="xl">
        <Tabs variant="scrollable" scrollButtons="auto" value={false}>
          {sections.map(({ href, label, id }) => (
            <Tab
              key={href}
              href={href}
              onClick={() => trackTabSwitchEvent(label)}
              label={label}
              // custom styles, because our requirements are different from the global theme
              // we don't have always active tab, but we mimic it when hovering
              sx={styles.Tab}
              data-cy={`${dataCy}-item-#${id}`}
            />
          ))}
        </Tabs>
      </Container>
    </Container>
  )
}
