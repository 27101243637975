import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

import { getAppConfigProps } from '@knauf-group/ct-shared-nextjs/client'
import { cloneDeep } from 'lodash'

import nextI18NextConfig from '../../../next-i18next.config'
import { expandURL } from '../plain/expandURL'
import { getConfentfulSharedProps } from './getConfentfulSharedProps'

type GetCommonServerPropsProps = {
  locale: string
  canonicalHrefPath: string
}

/**
 * Adds the canonical URL to the links that start with `/` or `#`
 * @param props sharedContentfulProps object itself
 * @param canonicalURL the canonical URL of the current page
 * @returns
 */
const updateHrefsInContentfulProps = <T>(props: T, canonicalURL: string): T => {
  // Helper function to recursively traverse the object
  function traverse(partialProps: any) {
    Object.keys(partialProps).forEach((key) => {
      if (key === 'href' && typeof partialProps[key] === 'string') {
        // the case where we might want to update the URL
        partialProps[key] = expandURL(partialProps[key], canonicalURL)
      } else if (typeof partialProps[key] === 'object' && partialProps[key] !== null) {
        // we update all `href`s in the props object, hence the recursive call
        traverse(partialProps[key]) // Recurse into nested objects
      }
    })

    return partialProps
  }

  return traverse(cloneDeep(props)) // Start traversal from the clone of the root `props`
}

export const getCommonServerProps = async (props: GetCommonServerPropsProps) => {
  const { locale, canonicalHrefPath } = props

  const [contentfulSharedProps, appConfigProps, translations] = await Promise.all([
    getConfentfulSharedProps(locale).then((contentfulProps) =>
      updateHrefsInContentfulProps(contentfulProps, canonicalHrefPath),
    ),
    getAppConfigProps(),
    serverSideTranslations(locale, nextI18NextConfig.ns as string[], nextI18NextConfig),
  ])

  return {
    ...contentfulSharedProps,
    ...appConfigProps,
    ...translations,
  }
}
