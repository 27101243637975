import logger from '@knauf-group/ct-shared-nextjs/logger'
import axios, { isAxiosError } from 'axios'

import type { Category, Product } from '@/types'
import type { SitemapDto } from '@/types/sitemap'

const aggregationService = axios.create({
  baseURL: process.env.DATA_PROVIDER_URL,
  auth: {
    username: process.env.DATA_PROVIDER_USERNAME,
    password: process.env.DATA_PROVIDER_PASSWORD,
  },
})

aggregationService.interceptors.request.use(
  (config) => {
    logger.debug(`Call ${config.method?.toUpperCase()} ${config.url}`)
    return config
  },
  (error) => {
    logger.error('unexpected error', { error })
    return Promise.reject(error)
  },
)

aggregationService.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const logMessage = error?.message || 'unexpected error'

    if (isAxiosError(error)) {
      const logDetails = {
        res: {
          status: error?.response?.status,
          data: error?.response?.data,
          statusText: error?.response?.statusText,
        },
        req: {
          url: error.config?.url,
          method: error?.request?.method,
          protocol: error?.request?.protocol,
          host: error?.request?.host,
          path: error?.request?.path,
        },
      }

      if (error?.response?.status === 404) {
        logger.info(logMessage, logDetails)
      } else {
        logger.error(logMessage, logDetails)
      }
    } else {
      logger.error(logMessage, error)
    }
    return Promise.reject(error)
  },
)

export const endpoints = {
  getProduct: (locale: string, productId: string) =>
    aggregationService.get<Product>(`/${locale}/products/${productId}`),
  getCategories: (locale: string) =>
    aggregationService.get<Record<string, Category[]>>(`/${locale}/categories`),
  getSitemap: (locale: string, entity: 'products' | 'categories') =>
    aggregationService.get<SitemapDto>(`/${locale}/sitemaps/${entity}`),
}
