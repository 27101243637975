import { Box, Tooltip } from '@mui/material'

import { KnaufImage } from '@/components/KnaufImage'
import type { CertificateImage } from '@/types'
import { useBreakpoints } from '@/utils/frontend/useBreakpoints'
import { isValidUrl } from '@/utils/plain/isValidUrl'

const styles = {
  wrapper: {
    display: 'flex',
    gap: '8px',
    flexWrap: 'wrap',
    flexDirection: 'row',
    whiteSpace: 'nowrap',
  },
}

const CERTIFICATE_IMAGE_HEIGHT_ON_DESKTOP = 80
const CERTIFICATE_IMAGE_HEIGHT_ON_MOBILE = 72

type CertificateScrollerProps = {
  certificateImages: CertificateImage[]
  dataCy?: string
}

export const CertificateScroller = (props: CertificateScrollerProps) => {
  const { certificateImages, dataCy } = props

  const { isDesktop } = useBreakpoints()

  if (!certificateImages || certificateImages?.length === 0) return null

  const certificatesWithValidThumbnail = certificateImages.filter((item) =>
    isValidUrl(item?.thumbnail?.url),
  )

  if (certificatesWithValidThumbnail.length === 0) return null

  const certificateImageHeight = isDesktop
    ? CERTIFICATE_IMAGE_HEIGHT_ON_DESKTOP
    : CERTIFICATE_IMAGE_HEIGHT_ON_MOBILE

  return (
    <Box sx={styles.wrapper} data-cy={`${dataCy}-CertificateScroller-container`}>
      {certificatesWithValidThumbnail?.map((item) => {
        const { url, name } = item.thumbnail

        return (
          <Tooltip
            title={name}
            key={url}
            data-cy={`${dataCy}-CertificateScroller-Tooltip-${url}`}
            PopperProps={{
              sx: {
                zIndex: 9999,
              },
            }}
          >
            <KnaufImage
              src={url}
              adobeParams={{ hei: certificateImageHeight }}
              alt={name}
              dataCy={`${dataCy}-CertificateScroller-image-${url}`}
              loading="eager"
              style={{
                height: certificateImageHeight,
                width: 'auto',
              }}
            />
          </Tooltip>
        )
      })}
    </Box>
  )
}
